import React from "react"
import "./src/styles/global.css"
import "./src/styles/custom.css"
import AuthProvider from "./src/components/Context/AuthProvider"
import CartProvider from "./src/components/Context/CartProvider"
import FcProvider from "./src/components/Context/FcProvider"

export const wrapRootElement = ({element}) =>(
  <AuthProvider>
    <FcProvider></FcProvider>
    <CartProvider>{element}</CartProvider>
  </AuthProvider>
)